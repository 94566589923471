import FifthSection from "./components/fifth-section";
import FirstSection from "./components/first-section";
import FourthSection from "./components/fourth-section";
import SecondSection from "./components/second-section";
import ThirdSection from "./components/third-section";
import Footer from "../../Components/Footer";
import "./style.scss";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LandingPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <main>
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <FifthSection />
      </main>
      <Footer />
    </>
  );
};

export default LandingPage;
