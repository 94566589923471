import { useState } from "react";
import Button from "../Button";
import "./style.scss";
import Popap from "../Popap";
import LandingForm from "../Form";
import { ROLES } from "../../../..";

const ThirdSection = () => {
  const [popap1, setPopap1] = useState(false);
  const [themeColor, setThemeColor] = useState("");

  return (
    <section className="third-section">
      <div className="third-section_wrapper">
        <h2>Преимущества для соискателей:</h2>
        <div className="third-section__text_wrapper">
          <div className="third-section__text">
            <h3>Умный поиск</h3>
            <p>
              Вы не будете тратить время и усилия на просмотр неподходящих
              вакансий. Наши алгоритмы анализируют большое количество параметров
              - ваши навыки, опыт, предпочтения - чтобы предложить вам наиболее
              подходящие предложения.
            </p>
          </div>
          <div className="third-section__text">
            <h3>Ваш профиль на сервисе - лучшее резюме</h3>
            <p>
              Мы разработали эффективную форму профиля специалиста, которая
              обеспечивает оптимальный формат подачи данных о вас потенциальным
              работодателям.
            </p>
          </div>
          <div className="third-section__text">
            <h3>Больше информации о вакансии</h3>
            <p>
              Вы будете видеть не только требования и предстоящие задачи, но и
              сведения о проекте, стеке технологий и команде, а также наличие
              тестового задания и временные затраты на его выполнение.
            </p>
          </div>
          <div className="third-section__text">
            <h3>Бонусы раннего доступа</h3>
            <p>
              При оформлении заявки до релиза сервиса вы получите возможность
              пройти интервью с нашими рекрутерами, создать предрелизный профиль
              и быть в числе первых кандидатов, которых рассмотрят
              компании-работодатели.
            </p>
          </div>
        </div>
        <Button
          text={"Заявка на ранний доступ"}
          color={"#FFEE96"}
          onClick={() => {
            setPopap1(true);
            setThemeColor("#FFEE96");
            document.body.style.overflow = "hidden";
          }}
        />
      </div>
      {popap1 && (
        <Popap popap={popap1} setPopap={setPopap1}>
          <LandingForm
            color={themeColor}
            role={ROLES.APPLICANT}
            setPopap={setPopap1}
          />
        </Popap>
      )}
    </section>
  );
};

export default ThirdSection;
