import "./style.scss";

const SecondSection = () => {
  return (
    <section className="second-section">
      <div className="second-section_wrapper">
        <h2>
          Интегрированная платформа, соединяющая лучших специалистов с ведущими
          компаниями в IT-сфере.
        </h2>
        <p>
          Привет! Мы - небольшая команда единомышленников из СНГ, воодушевленная
          идеей упростить поиск работы и персонала в IT. Для этого мы создаем
          WorkLink - платформу, которая отличается от привычных площадок и
          делает процесс поиска быстрее, точнее и эффективнее. Мы используем
          передовые технологии, чтобы учесть ваши уникальные потребности и
          предоставить точные и релевантные результаты.
        </p>
        <h2>Основной сервис</h2>
        <h3>Умный поиск работы и подбор персонала в IT.</h3>
        <p>
          Наш сервис основан на мощных алгоритмах анализа данных, машинного
          обучения и искусственного интеллекта. Мы предлагаем индивидуальные
          рекомендации, основанные на ваших профессиональных интересах, навыках
          и предпочтениях. Наша цель - помочь вам найти работу, которая
          соответствует вашим ожиданиям, или найти специалистов, которые
          идеально подойдут для вашей компании.
        </p>
      </div>
    </section>
  );
};

export default SecondSection;
