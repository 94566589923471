import "./style.scss";

const Modal = ({ popap, setPopap, children }) => {
  const closePopup = () => {
    setPopap(false);
    document.body.style.overflow = "auto";
  };
  return (
    <div
      className={popap ? "modal active" : "modal"}
      onClick={() => closePopup()}
    >
      <div className="modal-wrapper" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={() => closePopup()}>
          <span></span>
          <span></span>
        </button>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};
export default Modal;
