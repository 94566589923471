import { useForm } from "react-hook-form";
import cn from "classnames";
import "./style.scss";
import { useEffect, useState } from "react";
import { ROLES } from "../../../..";
import checkYellow from "../../../../assets/svg/check.svg";
import checkGreen from "../../../../assets/svg/check-employer.svg";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

const regExEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regExPhone = /^\+[0-9]{1,15}$/;

const LandingForm = ({ color, role, setPopap }) => {
  const [roleState, setRoleState] = useState(role);
  const [finishContent, setFinishContent] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [serverError, serServerError] = useState("");

  const onSubmit = async (data) => {
    try {
      const response = await axios.post("https://worklink.pro/mail.php", {
        role: data.applicant || data.employer,
        email: data.email,
        name: data.name,
        phone: data.phone,
      });
      console.log(response.data); // Обработка ответа от сервера
      if (response.status === 200) {
        setFinishContent(true);
      } else {
        serServerError("Ошибка. Пожалуйста, повторите позже");
      }
    } catch (error) {
      serServerError("Ошибка. Пожалуйста, повторите позже");
    }
  };

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const hasEmail = watch("email");
  const hasPhone = watch("phone");
  const consent = watch("consent");

  const validateForm = () => {
    if (!hasEmail && !hasPhone) {
      setContactError(true);
      setConsentError(false);
    } else if (!consent) {
      setContactError(false);
      setConsentError(true);
    } else {
      setContactError(false);
      setConsentError(false);
      getValues();
    }
  };

  const handleCheckboxChange = (e) => {
    setValue("consent", e.target.checked);
    if (e.target.checked) {
      setConsentError(false);
    } else {
      setConsentError(true);
    }
  };

  return (
    <>
      {finishContent ? (
        <div className="finish__popap_wrapper">
          <div
            className={
              role === ROLES.EMPLOYER
                ? "finish__popap_smile employer"
                : "finish__popap_smile applicant"
            }
          ></div>
          <div className="finish__popap_text">
            <p>Спасибо!</p>
            <p>Ваша заявка на ранний доступ к WorkLink принята!</p>
            <p>Скоро с вами свяжется наш сотрудник.</p>
          </div>
          <button
            className="submit__button"
            type="button"
            onClick={() => {
              setPopap(false);
              document.body.style.overflow = "auto";
            }}
          >
            Хорошо!
          </button>
        </div>
      ) : (
        <div className="fourth-section__popap">
          <div
            className="fourth-section__popap_title"
            style={{ backgroundColor: color }}
          >
            <h2>
              Оставьте заявку и получите одним из первых доступ к возможностям
              WorkLink
            </h2>
          </div>
          <form
            className="fourth-section__popap_form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-items_data">
              <div className="form-item">
                <label htmlFor="formName">Имя:</label>
                <input
                  type="text"
                  id="formName"
                  {...register("name", { required: false })}
                />
              </div>
              <div className={cn("form-item", contactError && "err-border")}>
                <label htmlFor="formEmail">E-mail:</label>
                <input
                  type="email"
                  id="formEmail"
                  {...register("email", {
                    pattern: {
                      value: regExEmail,
                      message: "Не верный формат email",
                    },
                  })}
                />
              </div>
              {errors.email && (
                <p className="err-mess">{errors.email.message}</p>
              )}

              <div className={cn("form-item", contactError && "err-border")}>
                <label htmlFor="formPhone"> Номер телефона:</label>
                <input
                  type="tel"
                  id="formPhone"
                  {...register("phone", { pattern: regExPhone })}
                />
              </div>
              {errors.phone && (
                <p className="err-mess">
                  Введите номер телефона, например +675522255221552
                </p>
              )}
            </div>

            <div className="form__radio-buttons__wrapper">
              <div className="form__radio-buttons">
                <input
                  {...register("applicant")}
                  type="radio"
                  value="applicant"
                  id="applicant"
                  checked={roleState === ROLES.APPLICANT}
                  onChange={() => setRoleState(ROLES.APPLICANT)}
                />
                <label htmlFor="applicant">Я соискатель</label>
              </div>
              <div className="form__radio-buttons">
                <input
                  {...register("employer")}
                  type="radio"
                  value="employer"
                  id="employer"
                  checked={roleState === ROLES.EMPLOYER}
                  onChange={() => setRoleState(ROLES.EMPLOYER)}
                />
                <label htmlFor="employer">Я работодатель</label>
              </div>
            </div>
            <div className="form__errors_button">
              {contactError && (
                <p className="error-message">Не указаны контактные данные</p>
              )}
              {consentError && (
                <p className="error-message">
                  Для продолжения нам требуется ваше согласие
                </p>
              )}
              {!contactError && !consentError && serverError && (
                <span className="error-message">{serverError}</span>
              )}

              <button
                className="submit__button"
                type="submit"
                onClick={validateForm}
              >
                Отправить
              </button>
            </div>

            <div className="form__aprove_wrapper">
              <div
                className={`form__aprove_checkbox_wrapper ${
                  consentError ? "error" : ""
                }`}
              >
                <input
                  type="checkbox"
                  id="formApprove"
                  {...register("consent", { required: true })}
                  onChange={handleCheckboxChange}
                />
                <div className="form__aprove_checkbox">
                  {consent && (
                    <img
                      src={role === ROLES.EMPLOYER ? checkGreen : checkYellow}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div className="form__aprove_label">
                <p>
                  {" "}
                  Я согласен на обработку{" "}
                  <NavLink to="/policy" target="_blank">
                    {" "}
                    персональных данных
                  </NavLink>
                </p>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default LandingForm;
