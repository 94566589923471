import "./style.scss";
import { NavLink, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <h3>Телефон: +77770375491</h3>
        <NavLink to="/policy" target="_blank">
          Политика конфиденциальности
        </NavLink>
        <NavLink to="/terms" target="_blank">
          Условия использования
        </NavLink>
        <h3>© 2023 WorkLink. Все права защищены.</h3>
      </div>
    </footer>
  );
};

export default Footer;
