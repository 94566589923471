import "./style.scss";

const FirstSection = () => {
  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__title">
          <h1>
            Work<span>Link</span>
          </h1>
          <h2>
            Умный поиск работы <br /> и подбор персонала в IT
          </h2>
        </div>
        <div className="header__image_wrapper">
          <div className="header__images-left">
            <div className="left__tag">#ДМС</div>
            <div className="left__tag">#Офис</div>
            <div className="left__tag">#Удаленка</div>
            <div className="left__tag">#Конференции</div>
            <div className="left__tag">#Оффер</div>
          </div>
          <div className="header__image"></div>
          <div className="header__images-right">
            <div className="right__tag">Python</div>
            <div className="right__tag">QA</div>
            <div className="right__tag">DevOps</div>
            <div className="right__tag">Go</div>
            <div className="right__tag">Java Script</div>
            <div className="right__tag">Project Manager</div>
            <div className="right__tag">Swift</div>
            <div className="right__tag">Java</div>
            <div className="right__tag">C++</div>
            <div className="right__tag">Designer</div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default FirstSection;
