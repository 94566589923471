import { useState } from "react";
import Button from "../Button";
import "./style.scss";
import Popap from "../Popap";
import LandingForm from "../Form";
import { ROLES } from "../../../..";

const FourthSection = () => {
  const [popap1, setPopap1] = useState(false);
  const [themeColor, setThemeColor] = useState("");

  return (
    <section className="fourth-section">
      <div className="fourth-section_wrapper">
        <h2>Преимущества для работодателей:</h2>
        <div className="fourth-section__text_wrapper">
          <div className="fourth-section__text">
            <h3>Качественный подбор персонала</h3>
            <p>
              WorkLink поможет вам найти специалистов, соответствующих вашим
              требованиям и целям компании. Для этого мы разработали расширенные
              профили специалистов, а наши алгоритмы анализируют и сопоставляют
              большое количество параметров.
            </p>
          </div>
          <div className="fourth-section__text">
            <h3>Быстрый и эффективный процесс найма</h3>
            <p>
              Наш сервис сокращает время и усилия, связанные с подбором
              персонала. Вы сможете быстро находить и привлекать подходящих
              кандидатов, что поможет вашей компании быть конкурентоспособной.
            </p>
          </div>
          <div className="fourth-section__text">
            <h3>Внутренняя служба рекрутинга</h3>
            <p>
              Мы можем полностью взять на себя процесс поиска и подбора
              специалистов, вам останется только выбрать и взять на работу
              лучшего.
            </p>
          </div>
          <div className="fourth-section__text">
            <h3>Бонусы раннего доступа</h3>
            <p>
              Мы навсегда удвоим количество бесплатных вакансий и просмотров
              профилей соискателей в месяц компаниям-клиентам внутренней службы
              рекрутинга, с которыми будем сотрудничать до релиза платформы.
            </p>
          </div>
        </div>
        <Button
          text={"Заявка на ранний доступ"}
          color={"#3BEEB8"}
          onClick={() => {
            setPopap1(true);
            setThemeColor("#3BEEB8");
            document.body.style.overflow = "hidden";
          }}
        />
      </div>
      {popap1 && (
        <Popap popap={popap1} setPopap={setPopap1}>
          <LandingForm
            color={themeColor}
            role={ROLES.EMPLOYER}
            setPopap={setPopap1}
          />
        </Popap>
      )}
    </section>
  );
};

export default FourthSection;
