import { NavLink, useLocation } from "react-router-dom";
import "./style.scss";
import { useEffect } from "react";

const Policy = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <main className="docs__main" style={{ position: "sticky", top: 0 }}>
      <div className="docs__wrapper">
        <div className="docs-close__wrapper">
          <NavLink
            className="docs-close"
            to="/"
            onClick={() => (document.body.style.overflow = "auto")}
          >
            <span></span>
            <span></span>
          </NavLink>
        </div>
        <h1>
          Work<span className="logo_link">Link</span>
        </h1>
        <h2>Политика конфиденциальности</h2>
        <div className="docs__content">
          <div className="docs__section">
            <h4>
              Настоящая Политика обработки персональных данных (далее -
              Политика) разработана в целях защиты персональных данных, прав и
              свобод физических лиц при обработке их персональных данных.
            </h4>
            <h4>
              Политика разъясняет субъектам персональных данных цели сбора,
              механизм использования и обработки их персональных данных, а также
              определяет права и их алгоритмы реализации в связи с
              использованием таких данных.
            </h4>
            <h4>
              Политика применяется к отношениям, возникшим в связи с
              использованием Товариществом с ограниченной ответственностью
              "Novapay” (Новапэй) персональных данных пользователей сайта
              worklink.pro, а также физических лиц, с которыми заключен
              гражданско-правовой договор, представителей контрагентов и иных
              лиц.
            </h4>
            <h4>
              Настоящая Политика не применяется к обработке персональных данных
              в процессе трудовой деятельности и при осуществлении
              административных процедур (в отношении работников и бывших
              работников).
            </h4>
            <h4>
              Политика публикуется в свободном доступе на сайте worklink.pro и
              действует с момента ее утверждения, если иное не указано в самой
              политике.
            </h4>
            <h4>
              В соответствии с законодательством Республики Казахстан
              Товарищество с ограниченной ответственностью "Novapay” (Новапэй)
              является оператором персональных данных. При организации и
              осуществлении обработки персональных данных Компания
              руководствуется требованиями Закона Республики Казахстан от 21 мая
              2013 года N 94-V «О персональных данных и их защите» и принятыми в
              соответствии с ним иными нормативными правовыми актами.
            </h4>
          </div>
          <div className="docs__section">
            <h3>1. Определения</h3>
            <h4>
              Для реализации целей настоящего Соглашения применяются следующие
              определения:
            </h4>
            <h4>
              Оператор — Товарищество с ограниченной ответственностью "Novapay”
              (Новапэй);
            </h4>
            <h4>
              Субъект персональных данных — физическое/юридическое лицо, в
              отношении которого осуществляется обработка персональных данных;
            </h4>
            <h4>
              Персональные данные — любая информация, относящаяся прямо или
              косвенно к определенному или определяемому
              физическому/юридическому лицу (Субъекту), а именно:
            </h4>
            <h4>
              1) основная информация о Субъекте, то есть его ФИО, пол,
              гражданство, рабочая автобиография (опыт работы), дата
              (день/месяц/год), адрес/сведения о местонахождении, почтовый
              индекс, наименование юридического лица, его место регистрации и
              нахождения, основные виды деятельности и иные сведения,
              содержащиеся в анкете Субъекта и которые Субъект предоставил(а) по
              собственному желанию;
            </h4>
            <h4>
              2) контактные данные и сведения об активности на Сайте, в том
              числе адрес электронной почты, телефон (мобильный), контакты в
              чатах и других электронных ресурсах, информацию из социальной
              сети, к которой Субъект предоставил(а) доступ, включая сетевой
              идентификатор (ID) своего аккаунта в социальных сетях;
            </h4>
            <h4>
              2) контактные данные и сведения об активности на Сайте, в том
              числе адрес электронной почты, телефон (мобильный), контакты в
              чатах и других электронных ресурсах, информацию из социальной
              сети, к которой Субъект предоставил(а) доступ, включая сетевой
              идентификатор (ID) своего аккаунта в социальных сетях;
            </h4>
            <h4>
              2) контактные данные и сведения об активности на Сайте, в том
              числе адрес электронной почты, телефон (мобильный), контакты в
              чатах и других электронных ресурсах, информацию из социальной
              сети, к которой Субъект предоставил(а) доступ, включая сетевой
              идентификатор (ID) своего аккаунта в социальных сетях;
            </h4>
            <h4>
              Распространение персональных данных — действия, направленные на
              ознакомление с персональными данными неопределенного круга лиц;
            </h4>
            <h4>
              Удаление персональных данных — действия, в результате которых
              становится невозможным восстановить персональные данные в
              информационных ресурсах (системах), содержащих персональные
              данные, и (или) в результате которых уничтожаются материальные
              носители персональных данных;
            </h4>
            <h4>
              Блокирование персональных данных — прекращение доступа к
              персональным данным без их удаления;
            </h4>
            <h4>
              Обезличивание персональных данных — действия, в результате которых
              становится невозможным без использования дополнительной информации
              определить принадлежность персональных данных конкретному Субъекту
              персональных данных;
            </h4>
            <h4>
              Обработка персональных данных — любое действие или совокупность
              действий, совершаемые с персональными данными, включая сбор,
              систематизацию, хранение, изменение, использование, обезличивание,
              блокирование, распространение, предоставление, удаление
              персональных данных;
            </h4>
            <h4>
              Трансграничная передача персональных данных – передача
              персональных данных на территорию иностранного государства.
            </h4>
            <h4>
              Заполняя и отправляя анкету на сайте worklink.pro (далее –
              «Сайт»), Субъект настоящим соглашением дает согласие на обработку
              своих персональных данных Товариществу с ограниченной
              ответственностью "Novapay” (Новапэй) в соответствии со следующими
              условиями:
            </h4>
          </div>
          <div className="docs__section">
            <h3>1. Порядок и условия обработки персональных данных</h3>
            <h4>
              1.1. Вся обработка персональных данных производиться в
              соответствии с законодательством.
            </h4>
            <h4>
              1.1. Вся обработка персональных данных производиться в
              соответствии с законодательством.
            </h4>
            <h4>
              В случае обработки персональных данных в виде распространения
              Оператор указывает конкретные его условия.
            </h4>
            <h4>
              1.3. Оператор ограничивает обработку персональных данных
              достижением конкретных, заранее заявленных целей обработки, а
              хранение персональных данных осуществляется в форме, позволяющей
              идентифицировать Субъекта персональных данных.
            </h4>
            <h4>
              1.4. Оператор осуществляет обработку только тех персональных
              данных, которые необходимы для выполнения целей такой обработки.
            </h4>
          </div>
          <div className="docs__section">
            <h3>2. Согласие, предоставляемое Субъектом</h3>
            <h4>
              2.1. Субъект предоставляет Оператору свои Персональные данные,
              указанные в настоящем Соглашении, и дает свободно сознательное
              согласие на Обработку своих персональных данных. 2.2. Согласие
              субъекта персональных данных может быть получено в письменной
              форме, в виде электронного документа или в иной электронной форме.
              В иной электронной форме согласие Субъекта персональных данных
              осуществляется посредством проставления субъектом персональных
              данных соответствующей галочки на Сайте.
            </h4>
            <h4>
              2.1. Субъект предоставляет Оператору свои Персональные данные,
              указанные в настоящем Соглашении, и дает свободно сознательное
              согласие на Обработку своих персональных данных. 2.2. Согласие
              субъекта персональных данных может быть получено в письменной
              форме, в виде электронного документа или в иной электронной форме.
              В иной электронной форме согласие Субъекта персональных данных
              осуществляется посредством проставления субъектом персональных
              данных соответствующей галочки на Сайте.
            </h4>
          </div>
          <div className="docs__section">
            <h3>3. Права субъекта</h3>
            <h4>
              3.1. Субъект персональных данных вправе требовать внести изменения
              в свои персональные данные в случае, если они являются неполными,
              устаревшими или неточными. В этих целях Субъект персональных
              данных прилагает соответствующие документы и (или) их заверенные в
              установленном порядке копии, подтверждающие необходимость
              внесения. Оператор в срок до 15 календарных дней с момента
              получения запроса внесет изменения в персональные данные, если они
              являются неполными, устаревшими или неточными.
            </h4>
            <h4>
              Также Субъект может самостоятельно изменить данные путем изменения
              соответствующих настроек получения рассылки/ изменения данных в
              личном кабинете.
            </h4>
            <h4>
              Также Субъект может самостоятельно изменить данные путем изменения
              соответствующих настроек получения рассылки/ изменения данных в
              личном кабинете.
            </h4>
            <h4>• подтверждение факта обработки персональных данных;</h4>
            <h4>• подтверждение факта обработки персональных данных;</h4>
            <h4>• правовые основания и цели обработки персональных данных;</h4>
            <h4>
              • наименование и место нахождения уполномоченного лица
              (уполномоченных лиц), если обработка персональных данных поручена
              такому лицу (лицам);
            </h4>
            <h4>• иную информацию, предусмотренную законодательством.</h4>
            <h4>
              В течение 5 рабочих дней после получения заявления Оператор
              предоставит запрашиваемую информацию (по п.3.2. данной Главы) либо
              уведомит о причинах отказа в ее предоставлении.
            </h4>
            <h4>
              3.3. Субъект персональных данных вправе получать от Оператора
              информацию о предоставлении своих персональных данных третьим
              лицам один раз в календарный год бесплатно. Оператор в срок до 15
              календарных дней с момента получения запроса предоставит
              информацию о том, какие персональные данные и кому предоставлялись
              в течение года, предшествовавшего дате подачи заявления, либо
              уведомит о причинах отказа в ее предоставлении.
            </h4>
            <h4>
              3.4. Субъект персональных данных вправе требовать бесплатного
              прекращения обработки своих персональных данных, включая их
              удаление, при отсутствии оснований для обработки персональных
              данных, установленных законодательством Республики Казахстан.
            </h4>
            <h4>
              Оператор в срок до 15 календарных дней с момента получения запроса
              прекратит обработку персональных данных, осуществит их удаление и
              уведомит Субъекта персональных данных об этом, за исключением
              случаев, когда Оператор вправе продолжить обработку персональных
              данных при наличии оснований, установленных законодательством
              Республики Казахстан. При отсутствии технической возможности
              удаления персональных данных Оператор примет меры по недопущению
              дальнейшей обработки персональных данных, включая их блокирование
              и уведомит об этом Субъекта персональных данных.
            </h4>
            <h4>Субъект может самостоятельно удалить учетную запись.</h4>
            <h4>
              3.5. Если субъект персональных данных посчитает, что обработка его
              персональных данных осуществляется с нарушением законодательства
              Республики Казахстан, то Субъект персональных данных может подать
              жалобу. Жалоба рассматривается в порядке, установленном
              законодательством Республики Казахстан об обращениях граждан и
              юридических лиц. Для реализации указанных выше прав Субъект может
              нарочно, лично или почтой направить заявление по следующим
              адресам:
            </h4>
            <h4>
              Адрес: Казахстан, город Алматы, Бостандыкский район, улица
              Лескова, здание 3, почтовый индекс 050040
            </h4>
            <h4>Эл почта: admin@worklink.pros</h4>
            <h4>
              Такие заявления должны содержать: фамилию, собственное имя,
              отчество (если такое имеется), дату рождения Субъекта персональных
              данных, адрес его места жительства (места пребывания); изложение
              сути требований; личную подпись или электронную цифровую подпись
              Субъекта персональных данных.
            </h4>
          </div>
          <div className="docs__section">
            <h3>4. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
            <h4>
              Сроки обработки персональных данных определяются в соответствии с
              целями, для которых они были собраны.
            </h4>
            <h4>
              Если какие-либо положения настоящей политики будут признаны
              несоответствующими законодательству Республики Казахстан или
              недействительными, это не влияет на действительность и
              правомочность прочих положений Политики.
            </h4>
            <h4>
              Если какие-либо положения настоящей политики будут признаны
              несоответствующими законодательству Республики Казахстан или
              недействительными, это не влияет на действительность и
              правомочность прочих положений Политики.
            </h4>
            <h4>
              Все, что не предусмотрено настоящей политикой, регулируется
              законодательством Республики Казахстан.
            </h4>
          </div>
        </div>
        <h5>© 2023 WorkLink</h5>
      </div>
    </main>
  );
};

export default Policy;
