import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { useEffect } from "react";

const Terms = ({ setPolicyPopap }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <main className="docs__main">
      <div className="docs__wrapper">
        <div className="docs-close__wrapper">
          <NavLink
            className="docs-close"
            to="/"
            onClick={() => (document.body.style.overflow = "auto")}
          >
            <span></span>
            <span></span>
          </NavLink>
        </div>
        <h1>
          Work<span className="logo_link">Link</span>
        </h1>
        <h2>Пользовательское соглашение</h2>
        <div className="docs__content">
          <div className="docs__section">
            <h4>
              Настоящее пользовательское соглашение (далее – Соглашение)
              является соглашением о порядке использования интернет-сайта,
              расположенного по адресу worklink.pro .
            </h4>
            <h4>
              Используя Услуги Товарищества с ограниченной ответственностью
              "Novapay” (Новапэй) Вы принимаете все условия и положения
              настоящего Соглашения. Использование вами Услуг Товарищества с
              ограниченной ответственностью "Novapay” (Новапэй) также
              регулируется{" "}
              <NavLink to="/policy" className="span_link" target="_blank">
                Политикой конфиденциальности
              </NavLink>
              , которая регламентирует сбор, использование, передачу и хранение
              персональных данных.
            </h4>
          </div>
          <div className="docs__section">
            <h3>ТЕРМИНЫ</h3>
            <h4>
              Сервис — сайт, на котором размещена информация по трудоустройству,
              работающий через Интернет, включающий в себя информационные
              материалы, чат и тд.;
            </h4>
            <h4>Услуги — комплекс действий по трудоустройству;</h4>
            <h4>
              Администратор Сервиса — Товарищество с ограниченной
              ответственностью "Novapay” (Новапэй), обеспечивающее работу
              сервиса;
            </h4>
            <h4>
              Исполнитель — Товарищество с ограниченной ответственностью
              "Novapay” (Новапэй), оказывающая услуги по принятию, обработкеи
              сохранению необходимой информации для трудоустройства;
            </h4>
            <h4>
              Пользователи — физические и юридические лица, зарегистрированные
              для использования Сервиса;
            </h4>
            <h4>Посетители — незарегистрированные пользователи Услуг;</h4>
            <h4>
              Личный кабинет — закрытая от публичного доступа часть сайта
              (программы), доступ Пользователя к которой осуществляетсяс
              использованием учетной записи;
            </h4>
            <h4>
              Учетная запись пользователя — совокупность информационных данных
              (персональных данных) о Пользователе, зафиксированныхв цифровом
              формате и необходимая для опознания Пользователя и предоставления
              доступа к его персональным данным.
            </h4>
          </div>
          <div className="docs__section">
            <h3>ПРЕДМЕТ СОГЛАШЕНИЯ</h3>
            <h4>
              Соглашение определяет правила использования Сервиса. В предмет
              входит использование Сервиса любым способом и в любой формев
              пределах его объявленных функциональных возможностей, включая:
            </h4>
            <h4>• просмотр размещенных на Сервисе материалов;</h4>
            <h4>
              • регистрация, присоединение и/или авторизация на Сервисе
              (создание учетной записи);
            </h4>
            <h4>
              • передача/заполнение/указание/предоставление на сайте необходимых
              персональных данных/информации, включая,но не ограничиваясь такими
              как: тексты, гипертекстовые ссылки, изображения, аудио и видео-
              файлы, сведения и/или иная информация;
            </h4>
            <h4>• формирование Пользователем учетной записи;</h4>
            <h4>
              • доступ личного резюме Пользователя потенциальному работодателю;
            </h4>
            <h4>
              • доступ личного резюме Пользователя потенциальному работодателю;
            </h4>
            <h4>• возможность приобретения и пользования платными услугами;</h4>
            <h4>• оплата посредством безналичного платежа.</h4>
            <h4>
              Исполнитель гарантирует, что он является правообладателем
              исключительных прав на программу/сайт.
            </h4>
            <h4>
              Настоящий Договор распространяется на Пользователей и на
              Посетителей.
            </h4>
            <h4>
              Сбор, обработка, использование и передача персональных данных
              Посетителей и Пользователей Услуг регулируются{" "}
              <NavLink to="/policy" className="span_link" target="_blank">
                Политикой конфиденциальности
              </NavLink>{" "}
              с учетом обновлений.
            </h4>
            <h4>
              Будучи Посетителем Вы можете пользоваться отдельными функциями
              Сервиса.
            </h4>
          </div>
          <div className="docs__section">
            <h3>ИЗМЕНЕНИЯ</h3>
            <h4>
              Исполнитель может периодически вносить изменения в настоящее
              Соглашение, Политику конфиденциальности. В случае внесения
              существенных изменений Исполнитель опубликует соответствующее
              уведомление посредством Услуг либо иным способом, чтобы
              предоставить Вам возможность ознакомиться с изменениями до их
              вступления в силу. Если Вы не согласны с какими-либо изменениями,
              вы имеете право закрыть свою учетную запись.
            </h4>
          </div>
          <div className="docs__section">
            <h3>УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ УСЛУГ</h3>
            <h4>
              Услуги не предназначены для использования лицами младше 16 лет.
            </h4>
            <h4>
              Регистрируясь на Сервисе и получая Услуги, Пользователь будет
              иметь только одну учетная запись, которая будет зарегистрирована
              на его настоящие имя и фамилию.
            </h4>
            <h4>
              Для регистрации Пользователь обязуется предоставить достоверную и
              полную информацию о себе по вопросам, предлагаемым в форме
              регистрации, и поддерживать эту информацию в актуальном состоянии.
              Если Пользователь предоставляет неверную информацию или <br /> у
              Администратора Сервиса есть основания полагать, что
              предоставленная Пользователем информация неполна или недостоверна,
              Администратор Сервиса имеет право по своему усмотрению
              заблокировать либо удалить учетную запись Пользователя и отказать
              Пользователю в использовании Сервиса.
            </h4>
            <h4>
              Персональная информация Пользователя, содержащаяся в учетной
              записи Пользователя, хранится и обрабатывается Администратором в
              соответствии с условиями{" "}
              <NavLink to="/policy" className="span_link" target="_blank">
                Политики конфиденциальности
              </NavLink>
              .
            </h4>
            <h4>Обязательства Пользователя:</h4>
            <h4>1) использовать надежный пароль и хранить его в тайне;</h4>
            <h4>
              2) выполнять требования закона и условия данного Соглашения.
              Пользователь несет ответственность за все, что происходит с
              использованием учетной записи, до тех пор, пока не закроет ее или
              не сообщит о ее неправомерном использовании другими лицами.
            </h4>
            <h4>
              При регистрации Пользователь самостоятельно выбирает себе логин
              (уникальное символьное имя учетной записи Пользователя) и пароль
              для доступа к учетной записи. Администратор Сервиса вправе
              запретить использование определенных логинов, а также
              устанавливать требования к логину и паролю (длина, допустимые
              символы и т.д.).
            </h4>
            <h4>
              Пользователь обязан немедленно уведомить Администратора сервиса о
              любом случае несанкционированного (не разрешенного Пользователем)
              доступа к Сервису с использованием учетной записи Пользователя
              и/или о любом нарушении (подозрениях о нарушении)
              конфиденциальности своих средств доступа к учетной записи.
              Администратор Сервиса не отвечает за возможную потерю или порчу
              данных, а также другие последствия любого характера, которые могут
              произойти из-за нарушения Пользователем положений этой части
              Соглашения.
            </h4>
            <h4>
              Пользователь вправе в любой момент удалить свою учетную запись.
              При удалении информация о пользователе перестает быть доступной
              самому пользователи и кому-либо еще, но Администратор Сервиса
              оставляет за собой право хранить ее в соответствии с
              законодательством.
            </h4>
            <h4>
              Пользователь не вправе воспроизводить, повторять и копировать,
              продавать и перепродавать, а также использовать для каких-либо
              коммерческих целей какие-либо части сервиса (включая контент,
              доступный Пользователю), или доступ к ним, кроме тех случаев,
              когда Пользователь получил такое разрешение от Администратора
              Сервиса, либо когда это прямо предусмотрено Соглашением.
              Администратор Сервиса вправе заблокировать или удалить учетную
              запись Пользователя и удалить любой контент без объяснения причин,
              в том числе в случае нарушения Пользователем условий Соглашения
              или условий иных документов Исполнителя, а также в случае
              неиспользования Сервиса в течение 1 календарного года.
            </h4>
          </div>
          <div className="docs__section">
            <h3>ОБМЕН ИНФОРМАЦИЕЙ</h3>
            <h4>
              Администратор Сервиса вправе устанавливать ограничения в
              использовании сервиса для всех Пользователей, либо для отдельных
              категорий Пользователей (в зависимости от места пребывания
              Пользователя, языка, на котором предоставляется сервис и т.д.), в
              том числе: наличие/отсутствие отдельных функций сервиса, любого
              другого контента, максимальное количество сообщений, которые могут
              быть отправлены или получены одним зарегистрированным
              пользователем, максимальный размер почтового сообщения или
              дискового пространства, максимальное количество обращений к
              Сервису за указанный период времени, максимальный срок хранения
              контента, специальные параметры загружаемого контента и т.д.
            </h4>
            <h4>
              Администратор Сервиса может запретить автоматическое обращение к
              своим сервисам, а также прекратить прием любой информации,
              сгенерированной автоматически (например, почтового спама).
              Администратор Сервиса вправе посылать своим пользователям
              информационные сообщения (смс-уведомления и email рассылки).
              Используя сервис, Пользователь также дает свое согласие на
              получение сообщений рекламного характера. Пользователь вправе
              отказаться от получения сообщений рекламного характера путем
              использования соответствующего функционала Сервиса.
            </h4>
            <h4>
              Сервис допускает обмен сообщениями и информацией с потенциальными
              работодателями, например, с помощью чата.
            </h4>
            <h4>
              Пользователь самостоятельно несет ответственность перед третьими
              лицами за свои действия, связанные с использованием Сервиса, в том
              числе, если такие действия приведут к нарушению прав и законных
              интересов третьих лиц, а также за соблюдение законодательства при
              использовании Сервиса.
            </h4>
          </div>
          <div className="docs__section">
            <h3>ПРАВА НА ИНТЕЛЛЕКТУАЛЬНУЮ СОБСТВЕННОСТЬ</h3>
            <h4>
              Исполнитель уведомляет Пользователей и Посетителей о наших правах
              на интеллектуальную собственность.
            </h4>
            <h4>
              Исполнитель сохраняет все права на свою интеллектуальную
              собственность в рамках предоставления Услуг.
            </h4>
            <h4>
              Все объекты, доступные при помощи Сервиса, в том числе элементы
              дизайна, текст, графические изображения, иллюстрации, видео,
              программы для ЭВМ, базы данных, музыка, звуки и другие объекты
              (далее – содержание Сервиса), а также любой контент, размещенный
              на Сервисе, являются объектами исключительных прав Администратора
              Сервиса, Пользователей и других правообладателей.
            </h4>
            <h4>
              Использование контента, а также каких-либо иных элементов сервисов
              возможно только в рамках функционала, предлагаемого Сервисом.
              Никакие элементы содержания сервиса, а также любой контент,
              размещенный на Сервисе, не может быть использован иным образом без
              предварительного разрешения правообладателя. Под использованием
              подразумеваются, в том числе: воспроизведение, копирование,
              переработка, распространение на любой основе и т.д. Использование
              Пользователем элементов содержания Сервиса, а также любого
              контента для личного некоммерческого использования, допускается
              при условии сохранения всех знаков охраны авторского права,
              смежных прав, товарных знаков, других уведомлений об авторстве,
              сохранения имени (или псевдонима) автора/наименования
              правообладателя в неизменном виде, сохранении соответствующего
              объекта в неизменном виде.
            </h4>
            <h4>
              Использование контента, а также каких-либо иных элементов сервисов
              возможно только в рамках функционала, предлагаемого Сервисом.
              Никакие элементы содержания сервиса, а также любой контент,
              размещенный на Сервисе, не может быть использован иным образом без
              предварительного разрешения правообладателя. Под использованием
              подразумеваются, в том числе: воспроизведение, копирование,
              переработка, распространение на любой основе и т.д. Использование
              Пользователем элементов содержания Сервиса, а также любого
              контента для личного некоммерческого использования, допускается
              при условии сохранения всех знаков охраны авторского права,
              смежных прав, товарных знаков, других уведомлений об авторстве,
              сохранения имени (или псевдонима) автора/наименования
              правообладателя в неизменном виде, сохранении соответствующего
              объекта в неизменном виде.
            </h4>
            <h4>
              Исполнитель несет ответственность за рекламу, размещенную им на
              Сервисе, в пределах, установленных законодательством.
            </h4>
          </div>
          <div className="docs__section">
            <h3>ПРАВА НА ИНТЕЛЛЕКТУАЛЬНУЮ СОБСТВЕННОСТЬ</h3>
            <h4>
              1. загружать, посылать, передавать или любым другим способом
              размещать и/или распространять контент, который является
              незаконным, вредоносным, клеветническим, оскорбляет
              нравственность, демонстрирует (или является пропагандой) насилия и
              жестокости, нарушает права интеллектуальной собственности,
              пропагандирует ненависть и/или дискриминацию людей по расовому,
              этническому, половому, религиозному, социальному признакам,
              содержит оскорбления в адрес каких-либо лиц или организаций,
              содержит элементы (или является пропагандой) порнографии, детской
              эротики, представляет собой рекламу (или является пропагандой)
              услуг сексуального характера (в том числе под видом иных услуг),
              разъясняет порядок изготовления, примененияили иного использования
              наркотических веществ или их аналогов, взрывчатых веществ или
              иного оружия;
            </h4>
            <h4>
              2. нарушать права третьих лиц, в том числе несовершеннолетних лиц
              и/или причинять им вред в любой форме;
            </h4>
            <h4>
              3. выдавать себя за другого человека или представителя организации
              и/или сообщества без достаточных на то прав, в том числеза
              сотрудников Исполнителя, а также применять любые другие формы и
              способы незаконного представительства других лиц в сети,а также
              вводить пользователей или Администратора Сервиса в заблуждение
              относительно свойств и характеристик каких-либо субъектов или
              объектов;
            </h4>
            <h4>
              4. несанкционированно собирать и хранить персональные данные
              других лиц;
            </h4>
            <h4>5. нарушать нормальную работу Cервиса;</h4>
            <h4>
              6. содействовать действиям, направленным на нарушение ограничений
              и запретов, налагаемых Соглашением;
            </h4>
            <h4>
              7. другим образом нарушать нормы законодательства, в том числе
              нормы международного права.
            </h4>
          </div>
          <div className="docs__section">
            <h3>ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</h3>
            <h4>
              Пользователь использует сервис на свой собственный риск.
              Администратор Сервиса не принимает на себя никакой
              ответственности,в том числе за соответствие Cервиса целям
              Пользователя.
            </h4>
            <h4>
              Исполнитель не гарантирует, что: Сервис соответствует/будет
              соответствовать требованиям Пользователя; Сервис будет
              предоставляться непрерывно, быстро, надежно и без ошибок;
              результаты, которые могут быть получены с использованием Сервиса,
              будут точными и надежными и могут использоваться для каких-либо
              целей или в каком-либо качестве (например, для установленияи/или
              подтверждения каких-либо фактов); качество какого-либо продукта,
              услуги, информации и пр., полученных с использованием Сервиса,
              будет соответствовать ожиданиям Пользователя.
            </h4>
            <h4>
              Любые информацию и/или материалы (в том числе загружаемое ПО,
              письма, какие-либо инструкции и руководства к действию и т.д.),
              доступ к которым Пользователь получает с использованием Сервиса,
              Пользователь может использовать на свой собственный страхи риск и
              самостоятельно несет ответственность за возможные последствия
              использования указанных информации и/или материалов,в том числе за
              ущерб, который это может причинить компьютеру Пользователя или
              третьим лицам, за потерю данных или любой другой вред.
            </h4>
            <h4>
              Исполнитель не несет ответственности за любые виды убытков,
              произошедшие вследствие использования Пользователем Сервиса.
            </h4>
            <h4>
              Исполнитель не несет ответственности за надежность, качество,
              временные сбои, перерывы и скорость работы Сервиса, а такжеза
              сохранность создаваемой, используемой и получаемой Пользователем
              информации.
            </h4>
            <h4>
              Администратор Сервиса не несет ответственности перед Пользователем
              и третьими лицами за:
            </h4>
            <h4>
              Разглашение информации, которое возникло не по вине Администратора
              Сервиса, или стало необходимым в рамках законодательства
              Республики Казахстан по запросу полномочных государственных
              органов.
            </h4>
            <h4>
              Пользователь использует информацию, Программу и Сервис
              добровольно, с условием полного принятия на себя всех рискови
              последствий, связанных с их использованием.
            </h4>
            <h4>
              Совместимость программных продуктов, доступ к которым
              предоставляется в процессе оказания Услуг, с конкретными
              аппаратными и программными средствами Пользователя.
            </h4>
          </div>
          <div className="docs__section">
            <h3>УРЕГУЛИРОВАНИЕ СПОРОВ</h3>
            <h4>
              В том случае если спор между Пользователем и Исполнителем дойдет
              до судебного разбирательства, и Пользователь и Исполнитель
              соглашаются с тем, что такой спор будет передан на рассмотрение в
              суд Республики Казахстан.
            </h4>
            <h4>
              Настоящий раздел не отменяет действие обязательных положений
              законодательства о защите прав потребителей, применимых в стране
              постоянного места жительства/регистрации Пользователя, где
              Исполнитель предоставляет Услуги.
            </h4>
          </div>
          <div className="docs__section">
            <h3>ОБЩИЕ ПОЛОЖЕНИЯ</h3>
            <h4>
              Настоящее Соглашение представляет собой договор между
              Пользователем и Исполнителем относительно порядка использования
              Cервиса и заменяет собой все предыдущие соглашения между
              Пользователем и Исполнителем. Исполнитель уважает права на
              интеллектуальную собственность других лиц. Поэтому требует, чтобы
              размещаемая Пользователями информация была достовернойи не
              нарушала права на интеллектуальную собственность и другие права
              третьих лиц. Стороны имеют право прекратить использование и
              оказывание Услуги в любое время, направив соответствующее
              уведомление другой Стороне. При этом отдельные права и
              обязательства Сторон останутся в силе. Если по тем или иным
              причинам одно или несколько положений настоящего Соглашения будут
              признаны недействительными или не имеющими юридической силы, это
              не оказывает влияния на действительность или применимость
              остальных положений Соглашения. Бездействие со стороны
              Администратора Сервиса в случае нарушения Пользователем либо иными
              пользователями положений Соглашений не лишает Исполнителя права
              предпринять соответствующие действия в защиту своих интересов
              позднее, а такжене означает отказ Исполнителя от своих прав в
              случае совершения в последующем подобных либо сходных нарушений.
              Пользователь в любое время может обратиться за тем, чтобы его
              персональные данные были удалены, заблокированы и т.д. на
              указанный Email: admin@worklink.pro
            </h4>
            <h4>
              Стороны имеют право прекратить использование и оказывание Услуги в
              любое время, направив соответствующее уведомление другой Стороне.
              При этом отдельные права и обязательства Сторон останутся в силе.
              Если по тем или иным причинам одно или несколько положений
              настоящего Соглашения будут признаны недействительными или не
              имеющими юридической силы, это не оказывает влияния на
              действительность или применимость остальных положений Соглашения.
            </h4>
            <h4>
              Бездействие со стороны Администратора Сервиса в случае нарушения
              Пользователем либо иными пользователями положений Соглашений не
              лишает Исполнителя права предпринять соответствующие действия в
              защиту своих интересов позднее, а такжене означает отказ
              Исполнителя от своих прав в случае совершения в последующем
              подобных либо сходных нарушений. Пользователь в любое время может
              обратиться за тем, чтобы его персональные данные были удалены,
              заблокированы и т.д. на указанный Email:{" "}
              <a href="mailto:admin@worklink.pro" className="span_link-cross">
                admin@worklink.pro
              </a>
            </h4>
          </div>
          <div className="docs__section">
            <h3>КОНТАКТНЫЕ ДАННЫЕ</h3>
            <h4>
              Казахстан, город Алматы, Бостандыкский район, улица Лескова,
              здание 3, почтовый индекс 050040 <br />{" "}
              <a href="mailto:admin@worklink.pro" className="span_link-cross">
                admin@worklink.pro
              </a>
            </h4>
          </div>
        </div>
        <h5>© 2023 WorkLink</h5>
      </div>
    </main>
  );
};

export default Terms;
