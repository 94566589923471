import "./style.scss";

const Popap = ({ popap, setPopap, children }) => {
  return (
    <div
      className={popap ? "popap active" : "popap"}
      onClick={() => {
        setPopap(false);
        document.body.style.overflow = "auto";
      }}
    >
      <div className="popap-wrapper" onClick={(e) => e.stopPropagation()}>
        <button
          className="popap-close"
          onClick={() => {
            setPopap(false);
            document.body.style.overflow = "auto";
          }}
        >
          <span></span>
          <span></span>
        </button>
        <div className="popap-content">{children}</div>
      </div>
    </div>
  );
};
export default Popap;
