import { useState } from "react";
import "./style.scss";
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

const FifthSection = () => {
  const [modal, setModal] = useState(false);
  const [serverError, serServerError] = useState("");

  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    try {
      const response = await axios.post("https://worklink.pro/mail.php", {
        email: data.email,
      });
      console.log(response.data); // Обработка ответа от сервера
      if (response.status === 200) {
        setModal(true);
      } else {
        serServerError("Ошибка. Пожалуйста, повторите позже");
      }
    } catch (error) {
      serServerError("Ошибка. Пожалуйста, повторите позже");
    }
  };

  return (
    <section className="fifth-section">
      <div className="fifth-section__wrapper">
        <div className="fifth-section__text">
          <h2>
            Присоединяйтесь <br /> к <span>WorkLink</span> уже сегодня{" "}
          </h2>
          <p>
            Оставьте свой электронный адрес, чтобы быть в курсе новостей сервиса
            и актуальных вакансий в IT-сфере.
          </p>
        </div>
        <form className="fifth-section__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form__inputs">
            <input
              placeholder="Введите e-mail"
              {...register("email", {
                required: true,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
              })}
            />
            <button type="submit">Отправить</button>
          </div>

          <div
            className={`form__aprove_wrapper ${errors.approve ? "error" : ""}`}
          >
            <input
              type="checkbox"
              id="formApprove"
              {...register("approve", {
                required: "Для продолжения нам требуется ваше согласие",
              })}
            />
            <label htmlFor="formApprove">
              <p>Я согласен на обработку</p>{" "}
              <NavLink to="/policy" target="_blank">
                {" "}
                персональных данных
              </NavLink>
            </label>
          </div>
          {errors && errors.approve && (
            <span className="error-message">{errors.approve?.message}</span>
          )}
          {!errors.approve && serverError && (
            <span className="error-message">{serverError}</span>
          )}
        </form>
      </div>
      {modal && (
        <Modal popap={modal} setPopap={setModal}>
          <div className="fifthSection__modal">
            <div className="fifthSection__modal_wrapper">
              <p>Спасибо за подписку!</p>
              <p>
                WorkLink скоро запустится. Мы будем держать вас в курсе новостей
                сервиса и обещаем писать только по делу.
              </p>
              <button type="button" onClick={() => setModal(false)}>
                Хорошо
              </button>
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default FifthSection;
