import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/Landing";
import Policy from "./Pages/Docs/policy";
import Terms from "./Pages/Docs/terms";

export const ROLES = {
  EMPLOYER: "EMPLOYER",
  APPLICANT: "APPLICANT",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<LandingPage />}></Route>
        <Route path="/policy" element={<Policy />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
